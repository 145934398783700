import AddFormComponent from "@/components/forms/AddFormComponent";
import EditFormComponent from "@/components/forms/EditFormComponent";
import FormComponents from "@/components/forms/FormComponents";
import FormRenderer from "@/components/forms/FormRenderer";
import NavigationContainer from "@/components/layout/NavigationContainer";
import LocationSidebar from "@/components/locations/LocationSidebar";
import Form from "@/models/forms/Form";
import FormComponent from "@/models/forms/FormComponent";
import { updateAppointmentForm } from "@/services/FormService";
import { getLocationById } from "@/services/LocationService";
import { logError } from "@/services/LoggingService";
import Store from "@/store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const AppointmentForm = () => {
  const { setIsLoading } = Store();
  const { locationId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formComponent, setFormComponent] = useState<FormComponent>();
  const [appointmentForm, setAppointmentForm] = useState({
    components: [],
  } as Form);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const location = await getLocationById(locationId!);
      setAppointmentForm(location.appointmentForm ?? appointmentForm);
    };
    fetchData()
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
  }, []);

  const addFormComponent = async (component: FormComponent) => {
    const updatedAppointmentForm = {
      ...appointmentForm,
      components: [...appointmentForm.components, component],
    };
    setAppointmentForm(updatedAppointmentForm);
    updateAppointmentForm(locationId!, updatedAppointmentForm).catch((error) =>
      logError(error),
    );
  };

  const editFormComponent = async (component: FormComponent) => {
    setFormComponent(component);
    setIsModalOpen(true);
  };

  const updateFormComponent = (component: FormComponent) => {
    const updatedAppointmentForm = {
      ...appointmentForm,
      components: appointmentForm.components.map((c) =>
        c.properties.id === component.properties.id ? component : c,
      ),
    };
    setAppointmentForm(updatedAppointmentForm);
    updateAppointmentForm(locationId!, updatedAppointmentForm).catch((error) =>
      logError(error),
    );
  };

  const deleteFormComponent = async (component: FormComponent) => {
    const updatedAppointmentForm = {
      ...appointmentForm,
      components: appointmentForm.components.filter(
        (c) => c.properties.id !== component.properties.id,
      ),
    };
    setAppointmentForm(updatedAppointmentForm);
    updateAppointmentForm(locationId!, updatedAppointmentForm).catch((error) =>
      logError(error),
    );
  };

  const reorderFormComponent = async (
    component: FormComponent,
    direction: "up" | "down",
  ) => {
    const index = appointmentForm.components.findIndex(
      (c) => c.properties.id === component.properties.id,
    );
    if (index === -1) return; // Component not found
    const newIndex = direction === "up" ? index - 1 : index + 1;
    // Ensure the new index is within bounds
    if (newIndex < 0 || newIndex >= appointmentForm.components.length) return;
    const newComponents = [...appointmentForm.components];
    const [movedComponent] = newComponents.splice(index, 1);
    newComponents.splice(newIndex, 0, movedComponent);
    const updatedAppointmentForm = {
      ...appointmentForm,
      components: newComponents,
    };
    setAppointmentForm(updatedAppointmentForm);
    updateAppointmentForm(locationId!, updatedAppointmentForm).catch((error) =>
      logError(error),
    );
  };

  return (
    <NavigationContainer sidebar={<LocationSidebar locationId={locationId!} />}>
      <div className="text-4xl text-monkey-purple">Appointment Form</div>
      <div className="flex">
        <div className="px-10 basis-1/2 grow">
          <div className="flex my-4 text-2xl">
            <div className="grow">Components</div>
            <div>
              <AddFormComponent onSuccess={addFormComponent} />
            </div>
          </div>
          <EditFormComponent
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            component={formComponent}
            onSuccess={updateFormComponent}
          />
          <FormComponents
            components={appointmentForm.components}
            editComponent={editFormComponent}
            deleteComponent={deleteFormComponent}
            reorderComponent={reorderFormComponent}
          />
        </div>
        <div className="hidden px-10 basis-1/2 sm:block">
          <div className="my-4 text-2xl">Preview</div>
          <FormRenderer form={appointmentForm} />
        </div>
      </div>
    </NavigationContainer>
  );
};

export default AppointmentForm;
