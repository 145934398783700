import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";

export const logError = (
  error: any,
  message?: string,
  sendToSentry: boolean = true,
): void => {
  console.error(error);
  if (sendToSentry) {
    Sentry.captureMessage(error);
  }
  toast.error(message ?? "Uh oh! Something went wrong. Please try again.", {
    toastId: "error",
  });
};
