import { logError } from "@/services/LoggingService";
import Store from "@/store";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NavigationContainer from "../../components/layout/NavigationContainer";
import LocationSidebar from "../../components/locations/LocationSidebar";
import Button from "../../components/ui/Button";
import Location from "../../models/locations/Location";
import {
  deleteLocationById,
  getLocationById,
} from "../../services/LocationService";

const Overview = () => {
  const { setIsLoading } = Store();
  const navigate = useNavigate();
  const { locationId } = useParams();
  const [location, setLocation] = useState<Location | null>(null);

  const deleteLocation = async () => {
    setIsLoading(true);
    await deleteLocationById(locationId!)
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
    navigate("/locations");
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const location = await getLocationById(locationId!);
      setLocation(location);
    };
    fetchData()
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
  }, [locationId]);

  return (
    <NavigationContainer sidebar={<LocationSidebar locationId={locationId!} />}>
      <div className="text-4xl text-monkey-purple">{location?.name}</div>
      <div className="flex">
        <div className="flex-grow">
          <a
            href={`/b/${location?.id}`}
          >{`${import.meta.env.VITE_APP_MONKEY_URL}/b/${location?.id}`}</a>
        </div>
        <div className="flex-grow"></div>
      </div>
      <div>
        <Button primary type="button" onClick={deleteLocation}>
          Delete Location
        </Button>
      </div>
    </NavigationContainer>
  );
};

export default Overview;
