import { app } from "@/firebase";
import Form from "@/models/forms/Form";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore/lite";

const db = getFirestore(app);

export const updateAppointmentForm = async (
  locationId: string,
  appointmentForm: Form,
) => {
  const locationDocRef = doc(db, "locations", locationId);
  const locationDoc = await getDoc(locationDocRef);
  if (!locationDoc.exists()) {
    throw new Error("Location not found");
  }
  const locationData = locationDoc.data();
  const updatedLocationData = {
    ...locationData,
    appointmentForm: appointmentForm,
  };
  await setDoc(locationDocRef, updatedLocationData);
};
