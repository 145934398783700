import LocationEmployee from "@/models/locations/LocationEmployee";
import { E164Number } from "libphonenumber-js";
import { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import AddEmployee from "../employees/AddEmployee";
import Button from "../ui/Button";
import PhoneNumber from "../ui/PhoneNumber";
import TextField from "../ui/TextField";
import UploadPlaceholderImage from "/images/upload-image-placeholder.jpg";

const LocationEmployeeDetails = (props: {
  employee: LocationEmployee;
  addEmployee: (employe: LocationEmployee) => void;
  updateEmployee: (employee: LocationEmployee) => void;
  removeEmployee: (employee: LocationEmployee) => void;
}) => {
  const {
    employee: initialEmployee,
    addEmployee,
    updateEmployee,
    removeEmployee,
  } = props;
  const [employee, setEmployee] = useState<LocationEmployee>(initialEmployee);
  const avatarEditor = useRef<AvatarEditor>(null);
  const [isEditingImage, setIsEditingImage] = useState(false);

  useEffect(() => {
    setEmployee(initialEmployee);
  }, [initialEmployee]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    updateEmployee(employee);
  };

  const onDrop = (acceptedFiles: File[]) => {
    setEmployee({ ...employee, image: URL.createObjectURL(acceptedFiles[0]) });
    setIsEditingImage(true);
  };

  const onImageClick = () => {
    setIsEditingImage(true);
  };

  const onSaveImage = () => {
    if (avatarEditor.current && employee.image) {
      const canvas = avatarEditor.current.getImageScaledToCanvas();
      const dataUrl = canvas.toDataURL();
      employee.image = dataUrl;
      setEmployee({ ...employee, image: dataUrl });
    }
    setIsEditingImage(false);
    updateEmployee(employee);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="my-4 text-2xl text-monkey-purple">Employee Details</div>
      <AddEmployee
        onSuccess={(employee) => {
          addEmployee(employee);
        }}
      />
      <div className="flex flex-wrap items-center justify-center my-4">
        <div className="min-w-[144px] my-4">
          <Dropzone onDrop={onDrop} noKeyboard>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="flex flex-col items-center">
                {isEditingImage ? (
                  <>
                    <AvatarEditor
                      width={150}
                      height={150}
                      image={employee.image || UploadPlaceholderImage}
                      ref={avatarEditor}
                      border={25}
                      color={[255, 255, 255, 0.6]} // RGBA
                      scale={1}
                      rotate={0}
                      borderRadius={100}
                    />
                    <div className="flex justify-center mt-2 space-x-4">
                      <Button
                        secondary
                        type="button"
                        className="w-32"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsEditingImage(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        primary
                        type="button"
                        className="w-32"
                        onClick={(e) => {
                          e.stopPropagation();
                          onSaveImage();
                        }}
                      >
                        Save Image
                      </Button>
                    </div>
                  </>
                ) : (
                  <img
                    src={employee.image || UploadPlaceholderImage}
                    alt="Employee"
                    className="rounded-full cursor-pointer w-36 h-36"
                    onClick={onImageClick}
                  />
                )}
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </div>
        <div className="flex flex-wrap flex-grow px-6">
          <div className="flex flex-wrap basis-full">
            <div className="min-w-[250px] basis-6/12 flex-grow px-2">
              <TextField
                elementId="employee-first-name"
                labelText="First name"
                required={true}
                onInput={(value: string) =>
                  setEmployee({ ...employee, firstName: value })
                }
                value={employee.firstName}
                autoFocus={true}
              />
            </div>
            <div className="min-w-[250px] basis-6/12 flex-grow px-2">
              <TextField
                elementId="employee-last-name"
                labelText="Last name"
                required={true}
                onInput={(value: string) =>
                  setEmployee({ ...employee, lastName: value })
                }
                value={employee.lastName}
              />
            </div>
          </div>
          <div className="flex flex-wrap basis-full">
            <div className="min-w-[250px] basis-6/12 flex-grow px-2">
              <TextField
                type="email"
                elementId="employee-email-address"
                labelText="Email"
                required={true}
                onInput={(value: string) =>
                  setEmployee({ ...employee, email: value })
                }
                value={employee.email}
              />
            </div>
            <div className="min-w-[250px] basis-6/12 flex-grow px-2">
              <PhoneNumber
                elementId="employee-phone-number"
                labelText="Phone number"
                required={false}
                onChange={(value: E164Number | undefined) =>
                  setEmployee({ ...employee, phone: value as string })
                }
                value={employee.phone as E164Number}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center space-x-4">
        <Button
          secondary
          type="button"
          onClick={() => removeEmployee(employee)}
        >
          Delete
        </Button>
        <Button primary type="submit">
          Save
        </Button>
      </div>
    </form>
  );
};

export default LocationEmployeeDetails;
