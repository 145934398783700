import Button from "@/components/ui/Button";
import Calendar from "@/components/ui/Calendar";
import TimeSelect from "@/components/ui/TimeSelect";
import Appointment from "@/models/appointments/Appointment";
import dayjs from "dayjs";
import { useState } from "react";
import Location from "../../models/locations/Location";

const AppointmentBookingDate = (props: {
  appointment: Appointment;
  location: Location;
  onBack: (appointment: Appointment) => void;
  onSubmit: (appointment: Appointment) => void;
}) => {
  const { appointment, location, onBack, onSubmit } = props;
  const [appointmentDate, setAppointmentDate] = useState(
    appointment.date || new Date(),
  );
  const [appointmentTime, setAppointmentTime] = useState(
    appointment.date ? dayjs(appointmentDate).format("h:mm A") : "",
  );
  const [updatedAppointment, setUpdatedAppointment] = useState(appointment);
  const [disableCalendar, setDisableCalendar] = useState(false);

  const setDate = (date: Date | undefined) => {
    if (date) {
      const newDate = dayjs(appointmentDate)
        .year(date.getFullYear())
        .month(date.getMonth())
        .date(date.getDate())
        .tz(location.timeZone)
        .toDate();
      setAppointmentDate(newDate);
      setUpdatedAppointment({
        ...updatedAppointment,
        date: newDate,
      });
    }
  };

  const setTime = (time: string) => {
    if (time) {
      const newDate = dayjs(
        `${appointmentDate.getFullYear()}/${appointmentDate.getMonth() + 1}/${appointmentDate.getDate()} ${time}`,
        "YYYY/M/D h:mm A",
      )
        .tz(location.timeZone)
        .toDate();
      setAppointmentDate(newDate);
      setAppointmentTime(dayjs(newDate).format("h:mm A"));
      setUpdatedAppointment({
        ...updatedAppointment,
        date: newDate,
      });
    }
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDisableCalendar(true);
    } else {
      // prevent changing calendar date when time is selected
      setTimeout(() => {
        setDisableCalendar(false);
      }, 2000);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(updatedAppointment);
      }}
    >
      <div className="mt-6 text-center">Select date and time</div>
      <div className="mt-4">
        <Calendar
          mode="single"
          selected={appointmentDate}
          onSelect={(date) => setDate(date)}
          className={`border rounded-md ${disableCalendar ? "pointer-events-none" : "pointer-events-auto"}`}
          required={true}
        />
      </div>
      <div className="mt-4 text-center">
        <TimeSelect
          time={appointmentTime}
          setTime={(time) => setTime(time)}
          onOpenChange={onOpenChange}
          required={true}
        />
      </div>
      <div className="flex justify-center mt-4 space-x-4">
        <Button
          secondary
          type="button"
          onClick={() => onBack(updatedAppointment)}
        >
          Back
        </Button>
        <Button primary type="submit">
          Continue
        </Button>
      </div>
    </form>
  );
};

export default AppointmentBookingDate;
