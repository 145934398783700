import { FaStore } from "react-icons/fa";
import Location from "../../models/locations/Location";

export const LocationTile = ({
  location,
  onClick,
}: {
  location: Location;
  onClick: () => void;
}) => {
  return (
    <div className="flex flex-col items-center w-[250px] m-6">
      <div
        className="relative w-[250px] h-[250px] m-6  bg-monkey-gray cursor-pointer flex justify-center items-center"
        onClick={onClick}
      >
        {location.image && (
          <div>
            <img src={location.image} onClick={onClick} />
          </div>
        )}
        {!location.image && (
          <div>
            <FaStore className="text-[120px] text-white" />
          </div>
        )}
      </div>
      <div className="text-2xl text-center">{location.name}</div>
    </div>
  );
};
