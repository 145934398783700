import Appointment from "@/models/appointments/Appointment";
import LocationService from "@/models/locations/LocationService";
import { PaymentStatus } from "@/utils/Enums";
import {
  getDayOfWeek,
  getHourFromTimeString,
  getMinuteFromTimeString,
} from "@/utils/Helpers";
import dayjs from "dayjs";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore/lite";
import { v4 as uuidv4 } from "uuid";
import { app } from "../firebase";
import { getEmployeeById } from "./EmployeeService";

const db = getFirestore(app);

export const createAppointment = async (appointment: Appointment) => {
  appointment.id = uuidv4();
  appointment.paymentStatus = PaymentStatus.Unpaid;
  appointment.created = dayjs(new Date()).tz(appointment.timeZone).toDate();
  await setDoc(doc(db, "appointments", appointment.id), appointment);
};

export const getAppointmentById = async (
  appointmentId: string,
): Promise<Appointment | null> => {
  const appointmentDoc = await getDoc(doc(db, "appointments", appointmentId));
  if (appointmentDoc.exists()) {
    return appointmentDoc.data() as Appointment;
  } else {
    console.error(`No appointment found with ID: ${appointmentId}`);
    return null;
  }
};

export const getAppointmentsByEmployeeId = async (
  employeeId: string,
  date?: Date,
): Promise<Appointment[]> => {
  let startDate, endDate;
  if (date) {
    startDate = date.setHours(0, 0, 0, 0);
    endDate = date.setHours(23, 59, 59, 999);
  }
  const appointments = collection(db, "appointments");
  let q;
  if (date) {
    q = query(
      appointments,
      where("employee.id", "==", employeeId),
      where("paymentStatus", "!=", "unpaid"),
      where("date", ">=", startDate),
      where("date", "<=", endDate),
    );
  } else {
    q = query(
      appointments,
      where("employee.id", "==", employeeId),
      where("paymentStatus", "!=", "unpaid"),
    );
  }
  const querySnapshot = await getDocs(q);
  const appointmentsArray = [] as Appointment[];
  querySnapshot.forEach((doc) => {
    const appointment = doc.data() as Appointment;
    appointment.date = doc.data().date.toDate();
    appointmentsArray.push(appointment);
  });
  return appointmentsArray;
};

export const getAppointmentsByEmployeeIds = async (
  employeeIds: string[],
): Promise<Appointment[]> => {
  if (employeeIds.length === 0) {
    return [];
  }
  const appointments = collection(db, "appointments");
  const querySnapshot = await getDocs(
    query(
      appointments,
      where("employee.id", "in", employeeIds),
      where("paymentStatus", "!=", "unpaid"),
    ),
  );
  const appointmentsArray = [] as Appointment[];
  querySnapshot.forEach((doc) => {
    const appointment = doc.data() as Appointment;
    appointment.date = doc.data().date.toDate();
    appointmentsArray.push(appointment);
  });
  return appointmentsArray;
};

export const updateAppointment = async (appointment: Appointment) => {
  await setDoc(doc(db, "appointments", appointment.id), appointment);
};

export const deleteAppointmentById = async (appointmentId: string) => {
  await deleteDoc(doc(db, "appointments", appointmentId));
};

export const getAvailableAppointments = async (
  employeeId: string,
  date: Date,
  service: LocationService,
) => {
  const employee = await getEmployeeById(employeeId);
  if (!employee) return [];

  const dayOfWeek = getDayOfWeek(date);
  const availabilities = employee.availability.filter((x) =>
    x.days.includes(dayOfWeek),
  );
  if (!availabilities.length) return [];

  const appointments = await getAppointmentsByEmployeeId(employeeId, date);
  let availableAppointments = [] as Date[];

  availabilities.forEach((availability) => {
    let start = dayjs(date)
      .hour(getHourFromTimeString(availability.startTime))
      .minute(getMinuteFromTimeString(availability.startTime));
    const end = dayjs(date)
      .hour(getHourFromTimeString(availability.endTime))
      .minute(getMinuteFromTimeString(availability.endTime));

    while (
      start.add(service.duration, "minutes").isBefore(end.add(1, "minute"))
    ) {
      availableAppointments.push(start.toDate());
      start = start.add(15, "minutes");
    }
  });

  const filterAppointments = (
    appointments: Date[],
    startDate: Date,
    endDate: Date,
  ) => {
    return appointments.filter(
      (availableAppointment) =>
        availableAppointment.getTime() <= startDate.getTime() ||
        availableAppointment.getTime() >= endDate.getTime(),
    );
  };

  appointments.forEach((appointment) => {
    const appointmentStartDate = appointment.date;
    const appointmentEndDate = dayjs(appointment.date)
      .add(service.duration, "minutes")
      .add(employee.timeBetweenAppointments, "minutes")
      .toDate();
    availableAppointments = filterAppointments(
      availableAppointments,
      appointmentStartDate,
      appointmentEndDate,
    );
  });

  employee.timeOff.forEach((timeOff) => {
    availableAppointments = filterAppointments(
      availableAppointments,
      timeOff.startDate,
      timeOff.endDate,
    );
  });

  return Array.from(
    new Set(availableAppointments.map((date) => date.getTime())),
  ).map((time) => new Date(time));
};
