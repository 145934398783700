import Location from "@/models/locations/Location";

export const isLocation = (data: any): data is Location => {
  return (
    data &&
    typeof data.id === "string" &&
    typeof data.name === "string" &&
    typeof data.image === "string" &&
    typeof data.address === "string" &&
    typeof data.timeZone === "string" &&
    typeof data.appointmentForm === "object" &&
    typeof data.autoConfirm === "boolean" &&
    Array.isArray(data.employees) &&
    Array.isArray(data.services) &&
    Array.isArray(data.admins) &&
    Array.isArray(data.users) &&
    typeof data.stripeAccountId === "string" &&
    typeof data.createdByUserId === "string"
  );
};
