import Form from "@/models/forms/Form";
import FormComponentProperties from "@/models/forms/FormComponentProperties";
import MultiSelectFormComponentProperties from "@/models/forms/MultiSelectFormComponentProperties";
import TextFieldFormComponentProperties from "@/models/forms/TextFieldFormComponentProperties";
import { FormComponentType } from "@/utils/Enums";
import MultiSelectFormComponent from "./MultiSelectFormComponent";
import TextFieldFormComponent from "./TextFieldFormComponent";

const FormRenderer = (props: {
  form: Form;
  onUpdate?: (form: Form) => void;
}) => {
  const { form, onUpdate } = props;

  const updateFormComponent = (
    index: number,
    properties: FormComponentProperties,
  ) => {
    const components = [...form.components];
    components[index].properties = properties;
    if (onUpdate) {
      onUpdate({ ...form, components });
    }
  };

  return (
    <div>
      {form.components.map((component, index) => (
        <div key={index}>
          {component.properties.type === FormComponentType.TextField && (
            <TextFieldFormComponent
              properties={
                component.properties as TextFieldFormComponentProperties
              }
              onUpdate={(properties: TextFieldFormComponentProperties) =>
                updateFormComponent(index, properties)
              }
            />
          )}
          {component.properties.type === FormComponentType.MultiSelect && (
            <MultiSelectFormComponent
              properties={
                component.properties as MultiSelectFormComponentProperties
              }
              onUpdate={(properties: MultiSelectFormComponentProperties) =>
                updateFormComponent(index, properties)
              }
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default FormRenderer;
