import LocationEmployee from "@/models/locations/LocationEmployee";
import { E164Number } from "libphonenumber-js";
import { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import Button from "../ui/Button";
import ModalDialog from "../ui/ModalDialog";
import PhoneNumber from "../ui/PhoneNumber";
import TextField from "../ui/TextField";
import UploadPlaceholderImage from "/images/upload-image-placeholder.jpg";

const AddEmployeeModalDialog = (props: {
  isModalOpen: boolean;
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void;
  onSuccess: (employee: LocationEmployee) => void;
}) => {
  const { isModalOpen, setIsModalOpen, onSuccess } = props;
  const [noClick, setNoClick] = useState(false);
  const [employee, setEmployee] = useState<LocationEmployee>({
    id: "",
    image: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    availability: [],
    timeOff: [],
    timeBetweenAppointments: 15,
    active: true,
    created: new Date(),
  });
  const avatarEditor = useRef<AvatarEditor>(null);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (avatarEditor.current && employee.image) {
      const canvas = avatarEditor.current.getImageScaledToCanvas();
      const dataUrl = canvas.toDataURL();
      employee.image = dataUrl;
    } else {
      employee.image = "";
    }
    onSuccess(employee);
    setIsModalOpen(false);
  };

  const onCancel = () => {
    setIsModalOpen(false);
  };

  const onDrop = (acceptedFiles: File[]) => {
    setEmployee({ ...employee, image: URL.createObjectURL(acceptedFiles[0]) });
  };

  const onImageChange = () => {
    setNoClick(true);
  };

  return (
    <ModalDialog isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <div className="mb-4 text-4xl text-center text-monkey-purple">
        Add Employee
      </div>
      <form onSubmit={onSubmit} className="space-y-4">
        <div className="flex items-center justify-center space-x-5">
          <Dropzone onDrop={onDrop} noKeyboard noClick={noClick}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <AvatarEditor
                  width={150}
                  height={150}
                  image={employee.image || UploadPlaceholderImage}
                  ref={avatarEditor}
                  border={25}
                  color={[255, 255, 255, 0.6]} // RGBA
                  scale={1}
                  rotate={0}
                  borderRadius={100}
                  onImageChange={onImageChange}
                />
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </div>
        <div>
          <TextField
            elementId="employee-first-name"
            labelText="First name"
            required={true}
            onInput={(value: string) =>
              setEmployee({ ...employee, firstName: value })
            }
            defaultValue={employee.firstName}
            autoFocus={true}
          />
        </div>
        <div>
          <TextField
            elementId="employee-last-name"
            labelText="Last name"
            required={true}
            onInput={(value: string) =>
              setEmployee({ ...employee, lastName: value })
            }
            defaultValue={employee.lastName}
          />
        </div>
        <div>
          <TextField
            type="email"
            elementId="employee-email-address"
            labelText="Email"
            required={true}
            onInput={(value: string) =>
              setEmployee({ ...employee, email: value })
            }
            defaultValue={employee.email}
          />
        </div>
        <div>
          <PhoneNumber
            elementId="employee-phone-number"
            labelText="Phone number"
            required={false}
            onChange={(value: E164Number | undefined) =>
              setEmployee({ ...employee, phone: value as string })
            }
            value={employee.phone as E164Number}
          />
        </div>
        <div className="flex justify-center space-x-4">
          <Button secondary type="button" onClick={onCancel}>
            Cancel
          </Button>
          <Button primary type="submit">
            Save
          </Button>
        </div>
      </form>
    </ModalDialog>
  );
};

export default AddEmployeeModalDialog;
