import Event from "@/models/schedules/Event";
import { refundPayment } from "@/services/PaymentService";
import Button from "../ui/Button";

const AppointmentDetail = (props: { event: Event; cancel: () => void }) => {
  const { event, cancel } = props;
  return (
    <div>
      <h1>Appointment Details</h1>
      <div>
        <div>
          <strong>Start:</strong> {event.start?.toString()}
        </div>
        <div>
          <strong>End:</strong> {event.end?.toString()}
        </div>
        <div>
          <strong>Title:</strong> {event.title}
        </div>
        <div>
          <strong>Appointment ID:</strong> {event.appointment.id}
        </div>
        <div>
          <strong>Payment Intent:</strong> {event.appointment.paymentIntent}
        </div>
        <Button
          warning
          type="button"
          onClick={() =>
            refundPayment(
              event.appointment.paymentIntent,
              event.location.stripeAccountId,
            )
          }
        >
          Refund
        </Button>
        <Button secondary type="button" onClick={cancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AppointmentDetail;
