import FormRenderer from "@/components/forms/FormRenderer";
import Button from "@/components/ui/Button";
import Appointment from "@/models/appointments/Appointment";
import Form from "@/models/forms/Form";

const AppointmentBookingForm = (props: {
  appointment: Appointment;
  onBack: (appointment: Appointment) => void;
  onSubmit: (appointment: Appointment) => void;
}) => {
  const { appointment, onBack, onSubmit } = props;
  const updateAppointmentForm = (updatedForm: Form) => {
    appointment.appointmentForm = updatedForm;
  };
  return (
    <div>
      <h1>Appointment Booking Form</h1>
      <FormRenderer
        form={appointment.appointmentForm}
        onUpdate={updateAppointmentForm}
      />
      <div>
        <div className="flex justify-center space-x-4">
          <Button
            secondary
            type="button"
            onClick={() => {
              onBack(appointment);
            }}
          >
            Back
          </Button>
          <Button
            primary
            type="button"
            onClick={() => {
              onSubmit(appointment);
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AppointmentBookingForm;
