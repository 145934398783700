import LocationEmployee from "@/models/locations/LocationEmployee";
import { doc, getDoc, getFirestore } from "firebase/firestore/lite";
import { app } from "../firebase";

const db = getFirestore(app);

export const getEmployeeById = async (
  employeeId: string,
): Promise<LocationEmployee | null> => {
  const employeeDoc = await getDoc(doc(db, "employees", employeeId));
  if (employeeDoc.exists()) {
    return employeeDoc.data() as LocationEmployee;
  } else {
    console.error(`No employee found with ID: ${employeeId}`);
    return null;
  }
};
