import EmployeeAvailabilityTable from "@/components/employees/EmployeeAvailabilityTable";
import EmployeeTimeOffTable from "@/components/employees/EmployeeTimeOffTable";
import NavigationContainer from "@/components/layout/NavigationContainer";
import LocationEmployeeDetails from "@/components/locations/LocationEmployeeDetails";
import LocationEmployeeFilter from "@/components/locations/LocationEmployeeFilter";
import LocationSidebar from "@/components/locations/LocationSidebar";
import EmployeeAvailability from "@/models/employees/EmployeeAvailability";
import EmployeeTimeOff from "@/models/employees/EmployeeTimeOff";
import Location from "@/models/locations/Location";
import LocationEmployee from "@/models/locations/LocationEmployee";
import { getLocationById, updateLocation } from "@/services/LocationService";
import { logError } from "@/services/LoggingService";
import { getFileURL } from "@/services/StorageService";
import Store from "@/store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Employees = () => {
  const { isLoading, setIsLoading } = Store();
  const { locationId } = useParams();
  const [location, setLocation] = useState<Location | null>(null);
  const [selectedEmployee, setSelectedEmployee] =
    useState<LocationEmployee | null>(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (!isLoading) setIsLoading(true);
    const fetchData = async () => {
      const location = await getLocationById(locationId!);
      setLocation(location);
      if (location.employees.length) {
        location.employees = await Promise.all(
          location.employees.map(async (employee) => {
            employee.image = (await getFileURL(employee.image)) ?? "";
            return employee;
          }),
        );
        if (!selectedEmployee) {
          setSelectedEmployee(location.employees[0]);
        }
      }
    };
    fetchData()
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
  }, [locationId, refresh]);

  const addEmployee = async (employee: LocationEmployee) => {
    setIsLoading(true);
    if (!location) {
      logError("No location selected");
      return;
    }
    const updatedLocation = {
      ...location,
      employees: [...location.employees, employee],
    };
    setLocation(updatedLocation);
    await updateLocation(updatedLocation);
    setRefresh(!refresh);
  };

  const updateEmployee = async (employee: LocationEmployee) => {
    setIsLoading(true);
    if (!location) {
      logError("No location selected");
      return;
    }
    const updatedLocation = {
      ...location,
      employees: location.employees.map((e) =>
        e.id === employee.id ? employee : e,
      ),
    };
    setLocation(updatedLocation);
    await updateLocation(updatedLocation);
    setRefresh(!refresh);
  };

  const removeEmployee = async (employee: LocationEmployee) => {
    setIsLoading(true);
    if (!location) {
      logError("No location selected");
      return;
    }
    const updatedLocation = {
      ...location,
      employees: location.employees.filter((e) => e.id !== employee.id),
    };
    setLocation(updatedLocation);
    await updateLocation(updatedLocation);
    setSelectedEmployee(null);
    setRefresh(!refresh);
  };

  const addEmployeeAvailability = (
    employeeAvailability: EmployeeAvailability,
  ) => {
    if (!selectedEmployee) {
      logError("No employee selected");
      return;
    } else if (!location) {
      logError("No location selected");
      return;
    }
    const updatedEmployee = {
      ...selectedEmployee,
      availability: [
        ...(selectedEmployee.availability || []),
        employeeAvailability,
      ],
    };
    setSelectedEmployee(updatedEmployee);
    const updatedLocation = {
      ...location,
      employees: location.employees.map((employee) =>
        employee.id === updatedEmployee.id ? updatedEmployee : employee,
      ),
    };
    setLocation(updatedLocation);
    updateLocation(updatedLocation);
  };

  const updateEmployeeAvailability = (
    employeeAvailability: EmployeeAvailability,
  ) => {
    if (!selectedEmployee) {
      logError("No employee selected");
      return;
    } else if (!location) {
      logError("No location selected");
      return;
    }
    const updatedEmployee = {
      ...selectedEmployee,
      availability: selectedEmployee.availability?.map((availability) =>
        availability.id === employeeAvailability.id
          ? employeeAvailability
          : availability,
      ),
    };
    setSelectedEmployee(updatedEmployee);
    const updatedLocation = {
      ...location,
      employees: location.employees.map((employee) =>
        employee.id === updatedEmployee.id ? updatedEmployee : employee,
      ),
    };
    setLocation(updatedLocation);
    updateLocation(updatedLocation);
  };

  const removeEmployeeAvailability = (
    employeeAvailability: EmployeeAvailability,
  ) => {
    if (!selectedEmployee) {
      logError("No employee selected");
      return;
    } else if (!location) {
      logError("No location selected");
      return;
    }
    const updatedEmployee = {
      ...selectedEmployee,
      availability: selectedEmployee.availability?.filter(
        (availability) => availability !== employeeAvailability,
      ),
    };
    setSelectedEmployee(updatedEmployee);
    const updatedLocation = {
      ...location,
      employees: location.employees.map((employee) =>
        employee.id === updatedEmployee.id ? updatedEmployee : employee,
      ),
    };
    setLocation(updatedLocation);
    updateLocation(updatedLocation);
  };

  const addEmployeeTimeOff = (employeeTimeOff: EmployeeTimeOff) => {
    if (!selectedEmployee) {
      logError("No employee selected");
      return;
    } else if (!location) {
      logError("No location selected");
      return;
    }
    const updatedEmployee = {
      ...selectedEmployee,
      timeOff: [...(selectedEmployee.timeOff || []), employeeTimeOff],
    };
    setSelectedEmployee(updatedEmployee);
    const updatedLocation = {
      ...location,
      employees: location.employees.map((employee) =>
        employee.id === updatedEmployee.id ? updatedEmployee : employee,
      ),
    };
    setLocation(updatedLocation);
    updateLocation(updatedLocation);
  };

  const updateEmployeeTimeOff = (employeeTimeOff: EmployeeTimeOff) => {
    if (!selectedEmployee) {
      logError("No employee selected");
      return;
    } else if (!location) {
      logError("No location selected");
      return;
    }
    const updatedEmployee = {
      ...selectedEmployee,
      timeOff: selectedEmployee.timeOff?.map((timeOff) =>
        timeOff.id === employeeTimeOff.id ? employeeTimeOff : timeOff,
      ),
    };
    setSelectedEmployee(updatedEmployee);
    const updatedLocation = {
      ...location,
      employees: location.employees.map((employee) =>
        employee.id === updatedEmployee.id ? updatedEmployee : employee,
      ),
    };
    setLocation(updatedLocation);
    updateLocation(updatedLocation);
  };

  const removeEmployeeTimeOff = (employeeTimeOff: EmployeeTimeOff) => {
    if (!selectedEmployee) {
      logError("No employee selected");
      return;
    } else if (!location) {
      logError("No location selected");
      return;
    }
    const updatedEmployee = {
      ...selectedEmployee,
      timeOff: selectedEmployee.timeOff?.filter(
        (timeOff) => timeOff !== employeeTimeOff,
      ),
    };
    setSelectedEmployee(updatedEmployee);
    const updatedLocation = {
      ...location,
      employees: location.employees.map((employee) =>
        employee.id === updatedEmployee.id ? updatedEmployee : employee,
      ),
    };
    setLocation(updatedLocation);
    updateLocation(updatedLocation);
  };

  return (
    <NavigationContainer sidebar={<LocationSidebar locationId={locationId!} />}>
      <div className="text-4xl text-monkey-purple">Employees</div>
      {selectedEmployee && (
        <>
          <LocationEmployeeFilter
            employees={location!.employees}
            selectedEmployee={selectedEmployee}
            setSelectedEmployee={setSelectedEmployee}
          />
          <LocationEmployeeDetails
            employee={selectedEmployee}
            addEmployee={addEmployee}
            updateEmployee={updateEmployee}
            removeEmployee={removeEmployee}
          />
          <EmployeeAvailabilityTable
            employee={selectedEmployee}
            addEmployeeAvailability={addEmployeeAvailability}
            updateEmployeeAvailability={updateEmployeeAvailability}
            removeEmployeeAvailability={removeEmployeeAvailability}
          />
          <EmployeeTimeOffTable
            employee={selectedEmployee}
            addEmployeeTimeOff={addEmployeeTimeOff}
            updateEmployeeTimeOff={updateEmployeeTimeOff}
            removeEmployeeTimeOff={removeEmployeeTimeOff}
          />
        </>
      )}
    </NavigationContainer>
  );
};

export default Employees;
