import Button from "@/components/ui/Button";
import AppointmentService from "@/models/appointments/AppointmentService";
import { formatPrice } from "@/utils/Helpers";
import Stripe from "stripe";

function AppointmentBookingService(props: {
  service: AppointmentService;
  onSelect: (service: AppointmentService) => void;
}) {
  const { service, onSelect } = props;
  const price = service.product.default_price as Stripe.Price;
  return (
    <div className="flex space-x-3">
      <div>
        <div className="flex items-baseline">
          <div>{service.product.name}</div>
          <div className="border-b-2 border-dotted grow"></div>
        </div>
        <div className="text-gray-500">{service.product.description}</div>
      </div>
      <div className="flex space-x-3">
        <div className="items-baseline">
          {price?.unit_amount && (
            <div>{formatPrice(price.unit_amount, price.currency)}</div>
          )}
          <div>30 min</div>
        </div>
        <Button primary type="button" onClick={() => onSelect(service)}>
          Select
        </Button>
      </div>
    </div>
  );
}

export default AppointmentBookingService;
