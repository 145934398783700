export enum PaymentStatus {
  Paid = "paid",
  Unpaid = "unpaid",
  Refund = "refund",
  PartialRefund = "partial_refund",
  NoPaymentRequired = "no_payment_required",
}

export enum FormComponentType {
  TextField = "text_field",
  MultiSelect = "multi_select",
}

export enum DayOfWeek {
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
  Sunday = "sunday",
}

export enum DurationUnits {
  Minutes = "minutes",
  Hours = "hours",
  Days = "days",
}
