import TextField from "@/components/ui/TextField";
import TextFieldFormComponentProperties from "@/models/forms/TextFieldFormComponentProperties";
import { useState } from "react";

const TextFieldFormComponent = (props: {
  properties: TextFieldFormComponentProperties;
  onUpdate?: (properties: TextFieldFormComponentProperties) => void;
}) => {
  const { onUpdate } = props;
  const { elementId, labelText, value } = props.properties;
  const [textFieldValue, setTextFieldValue] = useState(value ?? "");

  const onInput = (newValue: string) => {
    setTextFieldValue(newValue);
    const properties = {
      ...props.properties,
      value: newValue,
    };
    if (onUpdate) {
      onUpdate(properties);
    }
  };

  return (
    <TextField
      elementId={elementId}
      labelText={labelText}
      value={textFieldValue}
      onInput={onInput}
    />
  );
};

export default TextFieldFormComponent;
