import Button from "@/components/ui/Button";
import Appointment from "@/models/appointments/Appointment";
import AppointmentService from "@/models/appointments/AppointmentService";
import { logError } from "@/services/LoggingService";
import { getPaymentProducts } from "@/services/PaymentService";
import Store from "@/store";
import { useEffect, useState } from "react";
import AppointmentBookingService from "./AppointmentBookingService";

const AppointmentBookingServices = (props: {
  appointment: Appointment;
  accountId: string;
  onBack: (appointment: Appointment) => void;
  onSubmit: (appointment: Appointment) => void;
}) => {
  const { setIsLoading } = Store();
  const { appointment, accountId, onBack, onSubmit } = props;
  const [services, setServices] = useState<AppointmentService[]>([]);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const products = await getPaymentProducts(accountId);
      setServices(
        products.map((product) => {
          return {
            product: product,
          } as AppointmentService;
        }),
      );
    };
    fetchData()
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
  }, []);

  const selectService = (service: AppointmentService) => {
    appointment.service = service;
    onSubmit(appointment);
  };

  return (
    <div className="w-full px-4 mt-2">
      <div className="my-4 text-center">Select a service</div>
      <div>
        {services.map((service, i) => {
          return (
            <div key={i} className="flex my-8">
              <AppointmentBookingService
                service={service}
                onSelect={selectService}
              />
            </div>
          );
        })}
      </div>
      <div className="flex justify-center">
        <Button secondary type="button" onClick={() => onBack(appointment)}>
          Back
        </Button>
      </div>
    </div>
  );
};

export default AppointmentBookingServices;
