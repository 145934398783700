import { signOut } from "firebase/auth";
import { useEffect } from "react";
import { FaCircleUser, FaRightToBracket } from "react-icons/fa6";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import Store from "../../store";
import NavigationIcon from "./NavigationIcon";

const logout = () => {
  if (auth) {
    signOut(auth);
  }
};

const NavigationBar = () => {
  const {
    sidebarVisible,
    sidebarCollapsed,
    toggleSidebar,
    collapseSidebar,
    setSidebarVisible,
  } = Store();

  const navigate = useNavigate();

  useEffect(() => {
    setSidebarVisible();
    if (!sidebarCollapsed && window.innerWidth < 640) {
      collapseSidebar();
    }
  }, []);

  return (
    <div className="sticky top-0 z-10 flex items-center max-w-full bg-white border-b-2 text-monkey-purple border-b-monkey-purple">
      <img
        src="/images/logos/3.png"
        alt="Appointment Monkey"
        className="h-16 mx-2 cursor-pointer sm:h-24"
        onClick={() => navigate("/locations")}
      />
      <div className="flex-grow">
        <span className="hidden mt-10 italic sm:block">
          "It's actually not bad." - Steve M.
        </span>
      </div>
      <div className="flex cursor-pointer">
        {sidebarVisible && (
          <NavigationIcon onClick={toggleSidebar}>
            <GiHamburgerMenu />
            Menu
          </NavigationIcon>
        )}
        <NavigationIcon onClick={() => navigate("/account")}>
          <FaCircleUser />
          Account
        </NavigationIcon>
        <NavigationIcon onClick={logout}>
          <FaRightToBracket />
          Sign out
        </NavigationIcon>
      </div>
    </div>
  );
};

export default NavigationBar;
