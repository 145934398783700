import Appointment from "@/models/appointments/Appointment";
import { getAppointmentById } from "@/services/AppointmentService";
import { logError } from "@/services/LoggingService";
import Store from "@/store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function AppointmentConfirmation() {
  const { setIsLoading } = Store();
  const { appointmentId } = useParams();
  const [appointment, setAppointment] = useState<Appointment | null>(null);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const appointment = await getAppointmentById(appointmentId!);
      setAppointment(appointment);
    };
    fetchData()
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
  }, [appointmentId]);

  return (
    <div>
      <div>
        Success! Your appointment has been scheduled. We will send you an email
        when it has been confirmed by the establishment.
      </div>
      <div>Add to calendar</div>
      {appointment && (
        <div>{`Appointment Summary: ${JSON.stringify(appointment)}`}</div>
      )}
    </div>
  );
}

export default AppointmentConfirmation;
