import {
  deleteObject,
  getDownloadURL,
  getMetadata,
  getStorage,
  ref,
  uploadString,
} from "firebase/storage";

const storage = getStorage();

export const uploadFileFromDataURL = async (path: string, dataUrl: string) => {
  const storageRef = ref(storage, path);
  await uploadString(storageRef, dataUrl, "data_url");
};

export const getFileURL = async (path: string): Promise<string | null> => {
  if (!path) return null;
  const storageRef = ref(storage, path);
  const url = await getDownloadURL(storageRef);
  return url;
};

export const deleteFile = async (filePath: string) => {
  const fileRef = ref(storage, filePath);
  await deleteObject(fileRef);
};

export const fileExists = async (filePath: string): Promise<boolean> => {
  const fileRef = ref(storage, filePath);
  try {
    await getMetadata(fileRef);
    return true;
  } catch (error) {
    return false;
  }
};
