import EmployeeTimeOff from "@/models/employees/EmployeeTimeOff";
import EmployeeTimeOffModalDialog from "./EmployeeTimeOffModalDialog";

function EditEmployeeTimeOff(props: {
  employeeTimeOff?: EmployeeTimeOff;
  isModalOpen: boolean;
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void;
  onSuccess: (employeeTimeOff: EmployeeTimeOff) => void;
}) {
  const { employeeTimeOff, isModalOpen, setIsModalOpen, onSuccess } = props;
  return (
    <div>
      <EmployeeTimeOffModalDialog
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        employeeTimeOff={employeeTimeOff}
        onSuccess={(employeeTimeOff: EmployeeTimeOff) => {
          onSuccess(employeeTimeOff);
          setIsModalOpen(false);
        }}
      />
    </div>
  );
}

export default EditEmployeeTimeOff;
