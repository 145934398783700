import MultiSelect from "@/components/ui/MultiSelect";
import MultiSelectFormComponentProperties from "@/models/forms/MultiSelectFormComponentProperties";

const MultiSelectFormComponent = (props: {
  properties: MultiSelectFormComponentProperties;
  onUpdate?: (properties: MultiSelectFormComponentProperties) => void;
}) => {
  const { onUpdate } = props;
  const { prompt, items, selectedItems } = props.properties;

  const onChange = (newSelectedItems: string[]) => {
    const properties = {
      ...props.properties,
      selectedItems: newSelectedItems,
    };
    if (onUpdate) {
      onUpdate(properties);
    }
  };

  return (
    <MultiSelect
      prompt={prompt}
      items={items}
      selectedItems={selectedItems}
      onChange={onChange}
    />
  );
};

export default MultiSelectFormComponent;
