import Location from "@/models/locations/Location";
import { getDocWithDates, getDocsWithDates } from "@/utils/FirestoreHelpers";
import { isLocation } from "@/utils/TypeGuards";
import {
  collection,
  deleteDoc,
  doc,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore/lite";
import { v4 as uuidv4 } from "uuid";
import { app, auth } from "../firebase";
import {
  deleteFile,
  fileExists,
  uploadFileFromDataURL,
} from "./StorageService";
import { getUserById } from "./UserService";

const db = getFirestore(app);

export const getLocationById = async (locationId: string) => {
  const locationRef = doc(db, "locations", locationId);
  const locationData = await getDocWithDates(locationRef);
  if (locationData && isLocation(locationData)) {
    return locationData;
  }
  throw new Error("Invalid location data");
};

export const getLocationsByUserEmail = async (
  email: string | null,
): Promise<Location[]> => {
  if (!email) {
    console.error("User email is undefined");
    return [];
  }
  const locationsRef = collection(db, "locations");
  const adminsQuery = query(
    locationsRef,
    where("admins", "array-contains", email),
  );
  const usersQuery = query(
    locationsRef,
    where("users", "array-contains", email),
  );
  const [adminsData, usersData] = await Promise.all([
    getDocsWithDates(adminsQuery),
    getDocsWithDates(usersQuery),
  ]);
  const locationsMap = new Map<string, Location>();
  adminsData.forEach((data) => {
    if (isLocation(data)) {
      locationsMap.set(data.id, data);
    }
  });
  usersData.forEach((data) => {
    if (isLocation(data)) {
      locationsMap.set(data.id, data);
    }
  });
  return Array.from(locationsMap.values());
};

export const createLocation = async (location: Location) => {
  const user = await getUserById(auth?.currentUser?.uid);
  if (user?.stripeAccountId) {
    location.stripeAccountId = user.stripeAccountId;
  }
  await setDoc(doc(db, "locations", location.id), location);
  await updateLocation(location);
};

export const updateLocation = async (location: Location) => {
  if (!location.id) {
    location.id = uuidv4();
  }
  const imagePath = `/locations/${location.id}/${location.id}`;
  if (location.image.startsWith("data")) {
    if (await fileExists(imagePath)) {
      await deleteFile(imagePath);
    }
    await uploadFileFromDataURL(imagePath, location.image);
    location.image = imagePath;
  }
  for (const employee of location.employees) {
    if (!employee.id) {
      employee.id = uuidv4();
    }
    const imagePath = `/locations/${location.id}/${employee.id}`;
    if (employee.image.startsWith("data")) {
      if (await fileExists(imagePath)) {
        await deleteFile(imagePath);
      }
      await uploadFileFromDataURL(imagePath, employee.image);
      employee.image = imagePath;
    }
  }
  await setDoc(doc(db, "locations", location.id), location);
};

export const deleteLocationById = async (locationId: string) => {
  await deleteDoc(doc(db, "locations", locationId));
};
