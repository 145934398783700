import NavigationContainer from "@/components/layout/NavigationContainer";
import LocationServiceModalDialog from "@/components/locations/LocationServiceModalDialog";
import LocationSidebar from "@/components/locations/LocationSidebar";
import Button from "@/components/ui/Button";
import LocationService from "@/models/locations/LocationService";
import { getLocationById } from "@/services/LocationService";
import { logError } from "@/services/LoggingService";
import {
  createPaymentProduct,
  getPaymentProducts,
  updatePaymentProduct,
} from "@/services/PaymentService";
import Store from "@/store";
import { formatPrice } from "@/utils/Helpers";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Stripe from "stripe";

const Services = () => {
  const { setIsLoading } = Store();
  const { locationId } = useParams();
  const [selectedService, setSelectedService] =
    useState<LocationService | null>(null);
  const [services, setServices] = useState<LocationService[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const location = await getLocationById(locationId!);
      const products = await getPaymentProducts(location.stripeAccountId);
      setServices(
        products.map((product) => {
          return {
            product: product,
            duration: parseFloat(product?.metadata?.duration),
            durationUnits: product?.metadata?.duration_units,
          } as LocationService;
        }),
      );
    };
    fetchData()
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
  }, [refreshKey]);

  const addService = () => {
    setSelectedService(null);
    setIsModalOpen(true);
  };

  const editService = (service: LocationService) => {
    console.log(JSON.stringify(service));
    setSelectedService(service);
    setIsModalOpen(true);
  };

  const onSuccess = async (service: LocationService) => {
    setIsLoading(true);
    const price = service.product.default_price as Stripe.Price;
    if (service.product.id) {
      await updatePaymentProduct(
        service.product.id,
        locationId!,
        service.product.name,
        service.product.description,
        price.currency,
        (price.unit_amount ?? 0) / 100,
        service.duration,
        service.durationUnits,
      )
        .catch((error) => logError(error))
        .finally(() => setRefreshKey((prevKey) => prevKey + 1));
    } else {
      await createPaymentProduct(
        locationId!,
        service.product.name,
        service.product.description,
        price.currency,
        (price.unit_amount ?? 0) / 100,
        service.duration,
        service.durationUnits,
      )
        .catch((error) => logError(error))
        .finally(() => setRefreshKey((prevKey) => prevKey + 1));
    }
  };

  return (
    <NavigationContainer sidebar={<LocationSidebar locationId={locationId!} />}>
      <LocationServiceModalDialog
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        service={selectedService}
        onSuccess={(service: LocationService) => {
          onSuccess(service);
          setIsModalOpen(false);
        }}
      />
      <div className="text-4xl text-monkey-purple">Services</div>
      <div className="mt-4">
        <Button
          secondary
          type="button"
          onClick={addService}
          className="text-lg !min-w-[200px]"
        >
          Add New Service
        </Button>
      </div>
      {services.map((service, i) => {
        const price = service.product.default_price as Stripe.Price;
        return (
          <div
            key={i}
            className="flex my-4 cursor-pointer"
            onClick={() => editService(service)}
          >
            <div className="w-full p-4 bg-white rounded-lg shadow-md">
              <div className="flex items-center justify-between">
                <div className="text-lg font-bold">{service.product.name}</div>
                <div className="text-lg font-bold">
                  {price?.unit_amount && (
                    <div>{formatPrice(price.unit_amount, price.currency)}</div>
                  )}
                </div>
              </div>
              <div className="text-sm text-gray-500">
                {service.product.description}
              </div>
              <div>
                {service.duration} {service.durationUnits}
              </div>
            </div>
          </div>
        );
      })}
    </NavigationContainer>
  );
};

export default Services;
