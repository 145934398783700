import EmployeeAvailability from "@/models/employees/EmployeeAvailability";
import LocationEmployee from "@/models/locations/LocationEmployee";
import { DayOfWeek } from "@/utils/Enums";
import { formatDaysOfWeek } from "@/utils/Helpers";
import { useState } from "react";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import AddEmployeeAvailability from "./AddEmployeeAvailability";
import EditEmployeeAvailability from "./EditEmployeeAvailability";

interface Props {
  employee: LocationEmployee;
  addEmployeeAvailability: (employeeAvailability: EmployeeAvailability) => void;
  updateEmployeeAvailability: (
    employeeAvailability: EmployeeAvailability,
  ) => void;
  removeEmployeeAvailability: (
    employeeAvailability: EmployeeAvailability,
  ) => void;
}

const EmployeeAvailabilityTable: React.FC<Props> = ({
  employee,
  addEmployeeAvailability,
  updateEmployeeAvailability,
  removeEmployeeAvailability,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employeeAvailability, setEmployeeAvailability] = useState<
    EmployeeAvailability | undefined
  >(undefined);

  const editEmployeeAvailability = (
    employeeAvailability: EmployeeAvailability,
  ) => {
    setEmployeeAvailability(employeeAvailability);
    setIsModalOpen(true);
  };

  return (
    <>
      <EditEmployeeAvailability
        employeeAvailability={employeeAvailability}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onSuccess={(updatedAvailability) => {
          updateEmployeeAvailability(updatedAvailability);
          setIsModalOpen(false);
        }}
      />
      <div className="mt-4 overflow-auto">
        <h2 className="mt-4 text-2xl text-monkey-purple">Availability</h2>
        <AddEmployeeAvailability
          onSuccess={(employeeAvailability) =>
            addEmployeeAvailability(employeeAvailability)
          }
        />
        <table className="min-w-full mt-2 border-collapse whitespace-nowrap">
          <thead>
            <tr>
              <th className="px-4 py-2 border border-gray-200">Days</th>
              <th className="px-4 py-2 border border-gray-200">Start Time</th>
              <th className="px-4 py-2 border border-gray-200">End Time</th>
              <th className="px-4 py-2" style={{ width: "100px" }}></th>
            </tr>
          </thead>
          <tbody>
            {employee.availability?.map((availability, index) => (
              <tr key={index}>
                <td className="px-4 py-2 border border-gray-200">
                  {formatDaysOfWeek(availability.days as DayOfWeek[])}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {availability.startTime}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {availability.endTime}
                </td>
                <td
                  className="flex items-center justify-center space-x-2"
                  style={{ width: "100px" }}
                >
                  <button
                    className="flex items-center justify-center w-8 h-12 text-monkey-purple"
                    onClick={() => editEmployeeAvailability(availability)}
                    title="Edit"
                  >
                    <FaPencilAlt size={24} />
                  </button>
                  <button
                    className="flex items-center justify-center w-8 h-12 text-monkey-purple"
                    onClick={() => removeEmployeeAvailability(availability)}
                    title="Delete"
                  >
                    <FaTrash size={24} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EmployeeAvailabilityTable;
