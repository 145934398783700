import { Timestamp, getDoc, getDocs } from "firebase/firestore/lite";

export const convertTimestampsToDates = (obj: any): any => {
  if (obj instanceof Timestamp) {
    return obj.toDate();
  } else if (Array.isArray(obj)) {
    return obj.map(convertTimestampsToDates);
  } else if (obj !== null && typeof obj === "object") {
    const newObj: any = {};
    for (const key in obj) {
      newObj[key] = convertTimestampsToDates(obj[key]);
    }
    return newObj;
  }
  return obj;
};

export const getDocWithDates = async (docRef: any) => {
  const docSnapshot = await getDoc(docRef);
  const data = docSnapshot.data();
  return data ? convertTimestampsToDates(data) : null;
};

export const getDocsWithDates = async (query: any) => {
  const querySnapshot = await getDocs(query);
  return querySnapshot.docs.map((doc: any) => ({
    id: doc.id,
    ...convertTimestampsToDates(doc.data()),
  }));
};
