import LocationService from "@/models/locations/LocationService";
import { useEffect, useState } from "react";
import Stripe from "stripe";
import Button from "../ui/Button";
import Label from "../ui/Label";
import ModalDialog from "../ui/ModalDialog";
import MoneyInput from "../ui/MoneyInput";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/Select";
import TextField from "../ui/TextField";

const LocationServiceModalDialog = (props: {
  isModalOpen: boolean;
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void;
  service: LocationService | null;
  onSuccess: (service: LocationService) => void;
}) => {
  const { isModalOpen, setIsModalOpen, service, onSuccess } = props;
  const price = service?.product?.default_price as Stripe.Price;
  const [serviceName, setServiceName] = useState(service?.product?.name || "");
  const [serviceDescription, setServiceDescription] = useState(
    service?.product?.description || "",
  );
  const [servicePrice, setServicePrice] = useState(price?.unit_amount || 0);
  const [serviceCurrency, setServiceCurrency] = useState(
    price?.currency || "usd",
  );
  const [serviceDuration, setServiceDuration] = useState(
    service?.product?.metadata?.duration || 60,
  );
  const [serviceDurationUnits, setServiceDurationUnits] = useState(
    service?.product?.metadata?.duration_units || "min",
  );

  useEffect(() => {
    setServiceName(service?.product?.name || "");
    setServiceDescription(service?.product?.description || "");
    setServicePrice(price?.unit_amount || 0);
    setServiceCurrency(price?.currency || "usd");
    setServiceDuration(service?.product?.metadata?.duration || 60);
    setServiceDurationUnits(
      service?.product?.metadata?.duration_units || "min",
    );
  }, [service]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSuccess({
      product: {
        id: service?.product?.id,
        name: serviceName,
        description: serviceDescription,
        default_price: {
          currency: serviceCurrency,
          unit_amount: servicePrice * 100,
        } as Stripe.Price,
      } as Stripe.Product,
      duration: serviceDuration,
      durationUnits: serviceDurationUnits,
    } as LocationService);
  };

  const onCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <ModalDialog isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <div className="mb-2 text-4xl text-center text-monkey-purple">
        Add Service
      </div>
      <form onSubmit={onSubmit}>
        <TextField
          elementId="service-name"
          labelText="Name"
          placeholder="e.g. 60 Minute Massage"
          onInput={setServiceName}
          value={serviceName}
          required={true}
          autoFocus={true}
        />
        <TextField
          elementId="service-description"
          labelText="Description"
          placeholder="e.g. Relaxing massage with essential oils"
          onInput={setServiceDescription}
          value={serviceDescription}
        />
        <div className="flex space-x-4">
          <div className="basis-6/12">
            <MoneyInput
              elementId="service-price"
              labelText="Price"
              amount={servicePrice}
              setAmount={setServicePrice}
              currency={serviceCurrency}
              required={true}
            />
          </div>
          <div className="basis-6/12">
            <Label>Currency*</Label>
            <Select
              name="service-currency"
              value={serviceCurrency}
              defaultValue={serviceCurrency}
              onValueChange={setServiceCurrency}
              required={true}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Currency" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={"usd"}>USD</SelectItem>
                <SelectItem value={"cad"}>CAD</SelectItem>
                <SelectItem value={"eur"}>EUR</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="basis-6/12">
            <TextField
              elementId="service-duration"
              labelText="Duration"
              type="number"
              onInput={(value: string) => setServiceDuration(parseFloat(value))}
              value={serviceDuration.toString()}
              required={true}
            />
          </div>
          <div className="basis-6/12">
            <Label>Units*</Label>
            <Select
              name="service-duration-units"
              value={serviceDurationUnits}
              defaultValue={serviceDurationUnits}
              onValueChange={setServiceDurationUnits}
              required={true}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Units" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={"min"}>Minutes</SelectItem>
                <SelectItem value={"hours"}>Hours</SelectItem>
                <SelectItem value={"days"}>Days</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="flex items-center justify-center space-x-5">
          <Button secondary type="button" onClick={onCancel}>
            Cancel
          </Button>
          <Button primary type="submit">
            Save
          </Button>
        </div>
      </form>
    </ModalDialog>
  );
};

export default LocationServiceModalDialog;
