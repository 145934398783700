import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { DayOfWeek } from "./Enums";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function toTitleCase(s: string): string {
  return s
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function getParameterByName(name: string) {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regexS = "[\\?&]" + name + "=([^&#]*)";
  const regex = new RegExp(regexS);
  const results = regex.exec(window.location.href);
  if (results == null) return "";
  else return decodeURIComponent(results[1].replace(/\+/g, " "));
}

export function formatPrice(price: number, currency: string) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(price / 100);
}

export function getDayOfWeek(date: Date) {
  switch (date.getDay()) {
    case 0:
      return DayOfWeek.Sunday;
    case 1:
      return DayOfWeek.Monday;
    case 2:
      return DayOfWeek.Tuesday;
    case 3:
      return DayOfWeek.Wednesday;
    case 4:
      return DayOfWeek.Thursday;
    case 5:
      return DayOfWeek.Friday;
    case 6:
      return DayOfWeek.Saturday;
    default:
      throw new Error("Invalid day of week");
  }
}

export function formatDaysOfWeek(days: DayOfWeek[]) {
  if (days.length === 0) return "";

  const dayMap: Record<DayOfWeek, number> = {
    [DayOfWeek.Monday]: 1,
    [DayOfWeek.Tuesday]: 2,
    [DayOfWeek.Wednesday]: 3,
    [DayOfWeek.Thursday]: 4,
    [DayOfWeek.Friday]: 5,
    [DayOfWeek.Saturday]: 6,
    [DayOfWeek.Sunday]: 7,
  };

  const sortedDays = [...days].sort((a, b) => dayMap[a] - dayMap[b]);
  const ranges: string[] = [];
  let currentSequence: DayOfWeek[] = [sortedDays[0]];

  for (let i = 1; i < sortedDays.length; i++) {
    if (dayMap[sortedDays[i]] === dayMap[sortedDays[i - 1]] + 1) {
      currentSequence.push(sortedDays[i]);
    } else {
      if (currentSequence.length >= 3) {
        ranges.push(
          `${toTitleCase(currentSequence[0])} - ${toTitleCase(currentSequence[currentSequence.length - 1])}`,
        );
      } else {
        ranges.push(...currentSequence.map(toTitleCase));
      }
      currentSequence = [sortedDays[i]];
    }
  }

  if (currentSequence.length >= 3) {
    ranges.push(
      `${toTitleCase(currentSequence[0])} - ${toTitleCase(currentSequence[currentSequence.length - 1])}`,
    );
  } else {
    ranges.push(...currentSequence.map(toTitleCase));
  }

  return ranges.join(", ");
}

// time string format must be "h:mm A"
export function getHourFromTimeString(time: string) {
  let hour = parseInt(time.split(":")[0]);
  if (hour === 12 && time.includes("AM")) {
    hour = 0;
  } else if (hour !== 12 && time.includes("PM")) {
    hour += 12;
  }
  return hour;
}

// time string format must be "h:mm A"
export function getMinuteFromTimeString(time: string) {
  return parseInt(time.split(":")[1].split(" ")[0]);
}

// time string format will be "h:mm A"
export function getTimeFromDate(date: Date) {
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });
}

// time string format must be "h:mm A"
export function setDateTime(date: Date, time: string) {
  const hour = getHourFromTimeString(time);
  const minute = getMinuteFromTimeString(time);
  date.setHours(hour, minute);
  return date;
}

// date format must be MM/DD/YYYY
export function isValidDate(date: string) {
  const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
  return regex.test(date);
}

// time format must be h:mm A
export function isValidTime(time: string) {
  const regex = /^(1[0-2]|0?[1-9]):[0-5][0-9] [AP]M$/i;
  return regex.test(time);
}
