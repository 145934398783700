import Button from "@/components/ui/Button";
import Appointment from "@/models/appointments/Appointment";
import Location from "@/models/locations/Location";
import { getAppointmentById } from "@/services/AppointmentService";
import { getLocationById } from "@/services/LocationService";
import { logError } from "@/services/LoggingService";
import { createCheckoutSession } from "@/services/PaymentService";
import Store from "@/store";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Stripe from "stripe";

function AppointmentSummary() {
  const navigate = useNavigate();
  const { setIsLoading } = Store();
  const { appointmentId } = useParams();
  const [appointment, setAppointment] = useState<Appointment | null>(null);
  const [location, setLocation] = useState<Location | null>(null);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const appointment = await getAppointmentById(appointmentId!);
      const location = await getLocationById(appointment!.locationId);
      setAppointment(appointment);
      setLocation(location);
    };
    fetchData()
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
  }, [appointmentId]);

  return (
    <div className="mx-auto mt-8">
      {appointment && (
        <div>{`Appointment Summary: ${JSON.stringify(appointment)}`}</div>
      )}
      <div className="flex justify-center space-x-4">
        <Button
          secondary
          type="button"
          onClick={() => {
            navigate(`/b/${location!.id}`);
          }}
        >
          Cancel
        </Button>
        <Button
          primary
          type="button"
          onClick={async () => {
            setIsLoading(true);
            const session: Stripe.Checkout.Session | void =
              await createCheckoutSession(
                location!.stripeAccountId,
                (appointment!.service.product.default_price as Stripe.Price).id,
                appointment!.id,
                appointment!.contact.email,
                `${import.meta.env.VITE_APP_MONKEY_URL}/confirmation/${appointment!.id}`,
                `${import.meta.env.VITE_APP_MONKEY_URL}/summary/${appointment!.id}`,
              )
                .catch((error) => logError(error))
                .finally(() => setIsLoading(false));
            if (session?.url) {
              window.location.href = session.url;
            }
          }}
        >
          Finish and Pay
        </Button>
      </div>
    </div>
  );
}

export default AppointmentSummary;
