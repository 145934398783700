import Location from "@/models/locations/Location";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import AddressLookup from "../ui/AddressLookup";
import Button from "../ui/Button";
import TextField from "../ui/TextField";
import TimezoneSelect from "../ui/TimezoneSelect";
import PlaceholderImage from "/images/upload-image-placeholder.jpg";

const NewLocationDetails = (props: {
  location: Location;
  onContinue: (location: Location) => void;
}) => {
  const { location, onContinue } = props;
  const navigate = useNavigate();
  const avatarEditor = useRef<AvatarEditor>(null);
  const textInput = useRef<HTMLInputElement>(null);
  const addressLookup = useRef<HTMLInputElement>(null);
  const [noClick, setNoClick] = useState(false);
  const [newLocationName, setNewLocationName] = useState(location.name ?? "");
  const [newLocationAddress, setNewLocationAddress] = useState(
    location.address ?? "",
  );
  const [newLocationTimeZone, setNewLocationTimeZone] = useState(
    location.timeZone ?? "",
  );
  const [newLocationImage, setNewLocationImage] = useState<File | string>(
    location.image ?? "",
  );
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const timezoneData = useQuery({
    queryKey: ["timezone", latitude, longitude],
    queryFn: async () => {
      if (latitude && longitude) {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${new Date().getTime() / 1000}&key=${import.meta.env.VITE_GOOGLE_TIMEZONE_API_KEY}`,
        );
        return await response.json();
      } else {
        return null;
      }
    },
  });

  const onDrop = (acceptedFiles: File[]) => {
    setNewLocationImage(acceptedFiles[0]);
    setNoClick(true);
  };

  useEffect(() => {
    if (timezoneData && timezoneData.data && timezoneData.isSuccess) {
      setNewLocationTimeZone(timezoneData.data.timeZoneId);
    }
  }, [timezoneData]);

  useEffect(() => {
    textInput.current?.focus();
    if (addressLookup.current) {
      addressLookup.current.placeholder = "";
    }
  }, []);

  return (
    <div>
      <div className="max-w-screen-sm mx-auto mt-8 text-2xl text-center text-monkey-purple">
        New Location
      </div>
      <div className="flex justify-center">
        <Dropzone onDrop={onDrop} noKeyboard noClick={noClick}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <AvatarEditor
                width={250}
                height={250}
                image={newLocationImage || PlaceholderImage}
                ref={avatarEditor}
                border={25}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={1}
                rotate={0}
              />
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (avatarEditor.current && newLocationImage) {
            const canvas = avatarEditor.current.getImageScaledToCanvas();
            const dataUrl = canvas.toDataURL();
            location.image = dataUrl;
          }
          location.id = uuidv4();
          location.name = newLocationName;
          location.address = newLocationAddress;
          location.timeZone = newLocationTimeZone;
          onContinue(location);
        }}
        className="max-w-screen-sm mx-auto"
      >
        <TextField
          elementId="new-location-name"
          labelText="Name"
          required={true}
          ref={textInput}
          value={newLocationName}
          onInput={setNewLocationName}
        />
        <AddressLookup
          elementId="new-location-address"
          labelText="Address"
          required={true}
          ref={addressLookup}
          value={newLocationAddress}
          onInput={setNewLocationAddress}
          onPlaceSelected={(place: google.maps.places.PlaceResult) => {
            if (place !== undefined) {
              setNewLocationAddress(place.formatted_address ?? "");
              setLatitude(place.geometry?.location?.lat() ?? 0);
              setLongitude(place.geometry?.location?.lng() ?? 0);
            }
          }}
        />
        <TimezoneSelect
          timezone={newLocationTimeZone}
          setTimezone={setNewLocationTimeZone}
          required={true}
        />
        <div className="flex justify-center space-x-4">
          <Button
            secondary
            type="button"
            onClick={() => navigate("/locations")}
          >
            Cancel
          </Button>
          <Button primary type="submit">
            Continue
          </Button>
        </div>
      </form>
    </div>
  );
};

export default NewLocationDetails;
