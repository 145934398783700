import FormComponent from "@/models/forms/FormComponent";
import TextFieldFormComponentProperties from "@/models/forms/TextFieldFormComponentProperties";
import { FormComponentType } from "@/utils/Enums";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Button from "../ui/Button";
import TextField from "../ui/TextField";

const AddTextFieldFormComponent = (props: {
  component?: FormComponent;
  onSuccess: (component: FormComponent) => void;
  onCancel: () => void;
}) => {
  const { onSuccess, onCancel, component } = props;
  const properties = component?.properties as TextFieldFormComponentProperties;
  const [componentName, setComponentName] = useState(properties?.name || "");
  const [textFieldName, setTextFieldName] = useState(
    properties?.labelText || "",
  );

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSuccess({
      properties: {
        id: properties?.id || uuidv4(),
        type: FormComponentType.TextField,
        name: componentName,
        elementId: properties?.elementId || uuidv4(),
        labelText: textFieldName,
      } as TextFieldFormComponentProperties,
    } as FormComponent);
  };

  return (
    <form onSubmit={onSubmit}>
      <TextField
        elementId="component-name"
        labelText="Component name"
        onInput={setComponentName}
        value={componentName}
        required={true}
      />
      <TextField
        elementId="text-field-name"
        labelText="Text field name"
        onInput={setTextFieldName}
        value={textFieldName}
        required={true}
      />
      <div className="flex items-center justify-center space-x-5">
        <Button secondary type="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button primary type="submit">
          Save
        </Button>
      </div>
    </form>
  );
};

export default AddTextFieldFormComponent;
