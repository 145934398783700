import LocationEmployee from "@/models/locations/LocationEmployee";
import { useState } from "react";
import Button from "../ui/Button";
import AddEmployeeModalDialog from "./AddEmployeeModalDialog";

const AddEmployee = (props: {
  onSuccess: (employee: LocationEmployee) => void;
}) => {
  const { onSuccess } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div>
      <Button
        secondary
        type="button"
        onClick={() => setIsModalOpen(true)}
        className="text-lg"
      >
        Add Employee
      </Button>
      <AddEmployeeModalDialog
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onSuccess={(employee: LocationEmployee) => {
          onSuccess(employee);
          setIsModalOpen(false);
        }}
      />
    </div>
  );
};

export default AddEmployee;
