import FormComponent from "@/models/forms/FormComponent";
import MultiSelectFormComponentProperties from "@/models/forms/MultiSelectFormComponentProperties";
import { FormComponentType } from "@/utils/Enums";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { v4 as uuidv4 } from "uuid";
import Button from "../ui/Button";
import TextField from "../ui/TextField";

const AddMultiSelectFormComponent = (props: {
  component?: FormComponent;
  onSuccess: (component: FormComponent) => void;
  onCancel: () => void;
}) => {
  const { onSuccess, onCancel, component } = props;
  const properties =
    component?.properties as MultiSelectFormComponentProperties;
  const [prompt, setPrompt] = useState(properties?.prompt || "");
  const [item, setItem] = useState("");
  const [items, setItems] = useState(properties?.items || []);
  const [componentName, setComponentName] = useState(properties?.name || "");

  const addItem = () => {
    setItems([...items, item]);
    setItem("");
  };

  const removeItem = (index: number) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSuccess({
      properties: {
        id: properties?.id || uuidv4(),
        type: FormComponentType.MultiSelect,
        name: componentName,
        prompt: prompt,
        items: items,
      } as MultiSelectFormComponentProperties,
    } as FormComponent);
  };

  return (
    <form onSubmit={onSubmit}>
      <TextField
        elementId="component-name"
        labelText="Component name"
        onInput={setComponentName}
        value={componentName}
        required={true}
      />
      <TextField
        elementId="multi-select-prompt"
        labelText="Prompt"
        onInput={setPrompt}
        value={prompt}
        required={true}
      />
      <div className="flex space-x-5">
        <TextField
          elementId="add-item"
          labelText="Add item"
          onInput={setItem}
          value={item}
        ></TextField>
        <Button
          primary
          type="button"
          title="Add"
          className="!min-w-[40px] mt-[28px]"
          onClick={addItem}
        >
          +
        </Button>
      </div>
      <div>
        <div className="mb-2 text-lg text-monkey-purple">Items</div>
        <ul className="flex flex-wrap mb-5">
          {items.map((item, index) => (
            <li key={index} className="mx-2 mb-4">
              <div className="flex items-center p-2 rounded-lg bg-monkey-gray-light">
                {item}
                <button
                  title="Remove"
                  className="ml-1"
                  onClick={() => removeItem(index)}
                >
                  <IoMdClose />
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex items-center justify-center space-x-5">
        <Button secondary type="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button primary type="submit">
          Save
        </Button>
      </div>
    </form>
  );
};

export default AddMultiSelectFormComponent;
