import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/Select";
import Label from "./Label";

const times = [
  "12:00 AM",
  "12:30 AM",
  "1:00 AM",
  "1:30 AM",
  "2:00 AM",
  "2:30 AM",
  "3:00 AM",
  "3:30 AM",
  "4:00 AM",
  "4:30 AM",
  "5:00 AM",
  "5:30 AM",
  "6:00 AM",
  "6:30 AM",
  "7:00 AM",
  "7:30 AM",
  "8:00 AM",
  "8:30 AM",
  "9:00 AM",
  "9:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
];

const TimeSelect = (props: {
  time: string;
  setTime: (time: string) => void;
  onOpenChange?: (open: boolean) => void;
  required?: boolean;
}) => {
  const { time, setTime, onOpenChange } = props;
  const required = props.required === undefined ? false : props.required;

  return (
    <div className="w-full mb-5">
      <Label htmlFor="select-time">Time</Label>
      <Select
        name="select-time"
        value={time || ""}
        defaultValue={time || ""}
        onValueChange={(value) => setTime(value)}
        required={required}
        onOpenChange={onOpenChange}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Select time" />
        </SelectTrigger>
        <SelectContent>
          {times.map((time, i) => (
            <SelectItem key={i} value={time}>
              {time}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default TimeSelect;
