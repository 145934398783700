import AppointmentBookingContact from "@/components/appointments/AppointmentBookingContact";
import AppointmentBookingDate from "@/components/appointments/AppointmentBookingDate";
import AppointmentBookingEmployees from "@/components/appointments/AppointmentBookingEmployees";
import AppointmentBookingForm from "@/components/appointments/AppointmentBookingForm";
import AppointmentBookingServices from "@/components/appointments/AppointmentBookingServices";
import Appointment from "@/models/appointments/Appointment";
import LocationEmployee from "@/models/locations/LocationEmployee";
import { getLocationById } from "@/services/LocationService";
import { logError } from "@/services/LoggingService";
import { getFileURL } from "@/services/StorageService";
import Store from "@/store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Location from "../../models/locations/Location";

const AppointmentBooking = () => {
  const { setIsLoading } = Store();
  const { locationId } = useParams();
  const [step, setStep] = useState(1);
  const [location, setLocation] = useState({
    employees: [] as LocationEmployee[],
  } as Location);
  const [appointment, setAppointment] = useState({
    locationId: locationId,
  } as Appointment);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const location = await getLocationById(locationId!);
      location.image = (await getFileURL(location.image)) ?? "";
      setLocation(location);
      if (location.appointmentForm) {
        setAppointment({
          ...appointment,
          timeZone: location.timeZone,
          confirmed: location.autoConfirm,
          appointmentForm: location.appointmentForm,
        });
      } else {
        setAppointment({
          ...appointment,
          timeZone: location.timeZone,
          confirmed: location.autoConfirm,
        });
      }
    };
    fetchData()
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
  }, []);

  const setEmployee = (employee: LocationEmployee) => {
    setAppointment({ ...appointment, employee: employee });
    setStep(step + 1);
  };

  return (
    <div className="max-w-screen-sm px-2 mx-auto mt-8">
      <div className="flex flex-col items-center">
        <div className="text-5xl text-center">{location.name}</div>
        <div className="text-2xl text-center">{location.address}</div>
        {step === 1 && (
          <>
            <img className="mt-8" src={location.image} />
            <AppointmentBookingEmployees
              employees={location.employees}
              setEmployee={setEmployee}
            />
          </>
        )}
        {step === 2 && (
          <AppointmentBookingServices
            appointment={appointment}
            accountId={location.stripeAccountId}
            onBack={(appointment: Appointment) => {
              setAppointment(appointment);
              setStep(step - 1);
            }}
            onSubmit={(appointment: Appointment) => {
              setAppointment(appointment);
              setStep(step + 1);
            }}
          />
        )}
        {step === 3 && (
          <AppointmentBookingDate
            appointment={appointment}
            location={location}
            onBack={(appointment: Appointment) => {
              setAppointment(appointment);
              setStep(step - 1);
            }}
            onSubmit={(appointment: Appointment) => {
              setAppointment(appointment);
              if (location.appointmentForm) {
                setStep(step + 1);
              } else {
                setStep(step + 2);
              }
            }}
          />
        )}
        {step === 4 && (
          <AppointmentBookingForm
            appointment={appointment}
            onBack={(appointment: Appointment) => {
              setAppointment(appointment);
              setStep(step - 1);
            }}
            onSubmit={(appointment: Appointment) => {
              setAppointment(appointment);
              setStep(step + 1);
            }}
          ></AppointmentBookingForm>
        )}
        {step === 5 && (
          <AppointmentBookingContact
            appointment={appointment}
            onBack={(appointment: Appointment) => {
              setAppointment(appointment);
              if (location.appointmentForm) {
                setStep(step - 1);
              } else {
                setStep(step - 2);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AppointmentBooking;
