import { app, auth } from "@/firebase";
import User from "@/models/authentication/User";
import { doc, getDoc, getFirestore } from "firebase/firestore/lite";

const db = getFirestore(app);
const baseUrl = `https://${import.meta.env.VITE_FIREBASE_AUTH_DOMAIN}`;

export const getUserById = async (userId?: string): Promise<User | null> => {
  if (!userId) {
    throw new Error("User ID is undefined");
  }
  const docRef = doc(db, "users", userId);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    return null;
  }
  const userData = docSnap.data() as User | undefined;
  return userData ?? null;
};

export const verifyUserEmail = async () => {
  const url = new URL(`${baseUrl}/verifyUserEmail`);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
    },
  });
  return response.ok;
};
